import React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

const MybeTabs = styled(Tabs)({
  borderBottom: "1px solid #3f51b5",
  "& .MuiTabs-indicator": {
    backgroundColor: "red",
  },
});

const MybeTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "uppercase",
    minWidth: 150,
    fontWeight: theme.typography.fontWeightRegular,
    color: "#d9d9d9",
    "&:hover": {
      color: "#fff",
      opacity: 1,
    },
    "&.Mui-selected": {
      outline: "none",
      color: "#fff",
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

const a11yProps = (index) => {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
};

export default function ModuleTabs({
  value,
  tabs,
  setValue,
  handleChange,
  ...rest
}) {
  return (
    <div
      {...rest}
      style={{
        zIndex: 999,
        position: "fixed",
        flexGrow: 1,
        width: "100%",
        backgroundColor: "#fff",
      }}
    >
      <AppBar position="static">
        <MybeTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
          aria-label="scrollable prevent tabs example"
        >
          {tabs.map((item, key) => {
            return (
              <MybeTab
                label={item.label ? item.label : null}
                icon={<Icon>{item.icon}</Icon>}
                aria-label={item.label}
                {...a11yProps(key)}
                className={item?.className}
              />
            );
          })}
        </MybeTabs>
      </AppBar>
    </div>
  );
}
