import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";

import DataTable from "../common/DataTable";
import {
  ContractsApi,
  ApiLoaderComponent,
  LoadingComponent,
  StorageManager,
} from "@unity/components";
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "../common/ModuleFunctions";
import { StatusChip } from "../common/statusChip";
import { useTour } from "../services/TourContext";
import { expiredContract } from "./MockData/dealData";

const cachedItem = "contract-expired-page-initial-data";

export default function ExpiredContracts({
  context,
  create,
  read,
  edit,
  admin,
  readAll,
  bus,
}) {
  const tourOpen = useTour();

  const sm = new StorageManager();
  const initData = sm.getObject(cachedItem); // {page,pageSize,search,sort,filter}
  const localPage = initData?.page || null;
  const localPageSize = initData?.pageSize || null;
  const localSearch = initData?.search || null;
  const localSort = initData?.sort || null;
  const localFilter = initData?.filter || null;

  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [page, setPage] = useState(localPage || 0);
  const [pageSize, setPageSize] = useState(localPageSize || 10);
  const [pagination, setPagination] = useState(true); // Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0); // The total number of rows available from the search!
  const [searchText, setSearchText] = useState(localSearch || "");
  const [fetchTrigger, setFetchTrigger] = useState(0); // This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!
  const [sortModel, setSortModel] = useState(
    localSort
      ? [...localSort]
      : [
          {
            field: "",
            sort: "",
          },
        ]
  );
  const [filter, setFilter] = useState(
    localFilter
      ? { ...localFilter }
      : {
          items: [
            {
              columnField: "",
              operatorValue: "",
              value: "",
            },
          ],
        }
  );

  const history = useHistory();

  const getContracts = async () => {
    const res = await ContractsApi.getExpiredContracts({
      read_all: readAll,
      pagination: pagination,
      page: page + 1,
      per_page: pageSize,
      search_term: searchText,
      sort_name: sortModel.field,
      sort_direction: sortModel.sort,
      sort_name: sortModel[0]?.field,
      sort_direction: sortModel[0]?.sort,
      filter: filter.items[0],
    });

    if (res.success) {
      setData(res.data);
      if (pagination) {
        setRowCount(res.meta.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const handleSearchTextChange = (value) => {
    setSearchText(value);
    sm.setObject(cachedItem, { ...initData, search: value });
  };

  const handleSort = (sort) => {
    setSortModel(sort);
    sm.setObject(cachedItem, { ...initData, sort });
    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleFilter = (filter) => {
    setFilter(filter);
    sm.setObject(cachedItem, { ...initData, filter });
    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    sm.setItem(cachedItem, { ...initData, page });
    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);
    sm.setItem(cachedItem, { ...initData, pageSize: size });
    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleLinkClick = (event, params, path) => {
    event.preventDefault();
    history.push({
      pathname: `/contracts/${path}/${params.id}`,
      state: { ...params },
    });
  };

  const columns = [
    { field: "ref", headerName: "Ref#", flex: 0.1 },
    {
      field: "contract_name",
      valueGetter: (params) => params.row.name,
      headerName: "Name",
      flex: 0.3,
    },
    {
      field: "contract_start",
      valueGetter: (params) => params.row.start_date,
      headerName: "Start",
      flex: 0.1,
    },
    {
      field: "overall_end",
      headerName: "Overall End",
      flex: 0.1,
    },
    {
      field: "sbu_unit",
      headerName: "Business Unit",
      minWidth: 150,
      renderCell: (cellValues) => {
        if (cellValues.row.business_unit) {
          const pc = bus.find((e) => cellValues.row.business_unit === e.id);
          return pc.name;
        } else {
          return null;
        }
      },
    },
    {
      field: "customer_name",
      headerName: "Customer",
      minWidth: 200,
      renderCell: (cellValues) => {
        return (
          <Tooltip title={cellValues.customer_name}>
            <span>{cellValues.customer_name}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.1,
      renderCell: (cellValues) => <StatusChip status={cellValues.row.status} />,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 300,
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div className="tour-contract-expired-actions">
            <ModuleButton
              btnIcon="visibility"
              style={{
                background: context.theme.sidebar.background,
                color: "white",
              }}
              onClick={(event) =>
                handleLinkClick(event, cellValues.row, "view")
              }
              tip="View contract"
            />
            <ModuleButton
              btnIcon="download"
              style={{
                background: context.theme.sidebar.background,
                color: "white",
                marginLeft: 10,
              }}
              onClick={() =>
                ModuleFunctions.handleView({ contract: cellValues.row })
              }
              tip="Download original signed contract"
            />
          </div>
        );
      },
    },
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680,
  };

  useEffect(() => {
    if (tourOpen) {
      setData([expiredContract]);
    } else {
      getContracts();
    }
  }, [fetchTrigger]);

  if (read && data) {
    return (
      <>
        <div className="tour-contract-expired">
          <DataTable
            columns={columns}
            data={data}
            pagination={pagination}
            options={options}
            allowDelete={false}
            searchText={searchText}
            setSearchText={handleSearchTextChange}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
            sortModel={sortModel}
            setSortModel={handleSort}
            filter={filter}
            setFilter={handleFilter}
          />
        </div>
        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
