import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { DocumentVaultAPI } from "@unity/components";
import DropzoneModule from "../common/DropzoneModule";
import ModuleButton from "../common/ModuleButton";

export default function DynamicDocuments(props) {
  const [data, setData] = useState([]);

  const fileIcon = (fileType) => {
    if (!fileType) return "";

    const shortFileType = fileType.slice(-12); //This keeps the last 12 characters of the fiel to ensure a document named the as the file type will still show the correct icon

    if (shortFileType.includes("pdf")) {
      return (
        <div style={{ height: "45px", width: "45px", backgroundColor: "red" }}>
          {" "}
          <span style={{ color: "white" }}>
            <strong>PDF</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("sheet")) {
      return (
        <div
          style={{ height: "45px", width: "45px", backgroundColor: "#118144" }}
        >
          {" "}
          <span style={{ color: "white" }}>
            <strong>Excel</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("presentation")) {
      return (
        <div
          style={{ height: "45px", width: "45px", backgroundColor: "orange" }}
        >
          {" "}
          <span style={{ color: "white" }}>
            <strong>PowerP</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("document")) {
      return (
        <div
          style={{ height: "45px", width: "45px", backgroundColor: "#1a60c2" }}
        >
          {" "}
          <span style={{ color: "white" }}>
            <strong>Word</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("jpeg")) {
      return (
        <div style={{ height: "45px", width: "45px", backgroundColor: "gray" }}>
          {" "}
          <span style={{ color: "white" }}>
            <strong>JPEG</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("png")) {
      return (
        <div style={{ height: "45px", width: "45px", backgroundColor: "gray" }}>
          {" "}
          <span style={{ color: "white" }}>
            <strong>PNG</strong>
          </span>
        </div>
      );
    } else {
      return fileType;
    }
  };

  const handleViewDocument = async (vault_id) => {
    const res = await DocumentVaultAPI.generateTempLink(vault_id.id);
    window.open(res.data, "_blank");
  };

  function byteConverter(bytes, decimals, only) {
    const K_UNIT = 1024;
    const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

    if (bytes == 0) return "0 Byte";

    if (only === "MB")
      return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " MB";

    let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
    let resp =
      parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) +
      " " +
      SIZES[i];

    return resp;
  }

  const loadInitData = async () => {
    let test =
      props.data[props.productIndex].lines[props.lineIndex].options[
        props.optionIndex
      ];
    if (test && test.option_docs) {
      setData(test.option_docs);
    }
  };

  useEffect(() => {
    if (props.open) loadInitData();
  }, [props.open]);

  if (!props.open) {
    return null;
  }

  const handleAddEvidence = (files) => {
    let optionDocsArr = data && Array.isArray(data) ? [...data] : [];
    files.forEach((el) => {
      optionDocsArr.push(el);
    });
    let products = props.data;
    let product = products[props.productIndex];
    let line = product.lines[props.lineIndex];
    let option = line.options[props.optionIndex];
    option.option_docs = optionDocsArr;
    props.updateProduct(product.id, product);
    props.handleChange(products);
  };

  const removeEvidence = (file) => {
    let optionDocsArr =
      data && Array.isArray(data) ? data.filter((el) => el.id !== file.id) : [];
    let products = props.data;
    let product = products[props.productIndex];
    let line = product.lines[props.lineIndex];
    let option = line.options[props.optionIndex];
    option.option_docs = optionDocsArr;
    props.updateProduct(product.id, product);
    props.handleChange(products);
  };

  /*
products[1].lines[0].options[0].<option_docs>
  */

  const customContent = (
    <div>
      <h2>Documents</h2>
      <div className="form-row">
        <div className="form-group col-lg-12">
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>name</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    File Type
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    File Size
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Upload Date
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(data) &&
                  data.map((row, key) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {fileIcon(row.raw_type)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {byteConverter(row.size)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.created_at}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <ModuleButton
                          btnIcon="visibility"
                          tip="View document"
                          onClick={() => handleViewDocument(row)}
                        />
                        {props.type === "builder" ? null : (
                          <ModuleButton
                            btnIcon="delete"
                            style={{
                              backgroundColor: "red",
                              marginLeft: "10px",
                            }}
                            onClick={() => removeEvidence(row)}
                            tip="Delete"
                            disabled={props.readOnly}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );

  return (
    <DropzoneModule
      open={props.open}
      setOpen={() => {
        props.onClose();
        setData();
      }}
      onUpdate={handleAddEvidence}
      customContent={customContent}
      readOnly={props.readOnly}
    />
  );
}
