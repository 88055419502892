import React, { useEffect, useState } from "react";
import ContractForm from "../modules/ContractForm";
import { ContractsApi, AppHeaderComponent, LoadingComponent, ApiLoaderComponent, DealBuilderApi } from "@unity/components";
import { useHistory } from "react-router";
import { CanCreate } from "../services/Permissions";
import ModuleFunctions from "../common/ModuleFunctions";

export default function ContractCreate(props) {
  const [contract, setContract] = useState(false);
  const [bus, setBus] = useState(false);
  const [clients, setClients] = useState(false);
  const [contacts, setContacts] = useState(false);
  const [sites, setSites] = useState(false);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState({ status: false });

  let history = useHistory();

  const create = CanCreate("contracts");

  const busChange = (data) => {
    setBus(data);
  };

  const clientsChange = (data) => {
    setClients(data);
  };

  const contactsChange = (data) => {
    setContacts(data);
  };

  const sitesChange = (data) => {
    setSites(data);
  };

  const contractChange = (data) => {
    setContract(prevState => ({
      ...prevState,
      ...data
    }));
  };

  useEffect(() => {
    ModuleFunctions.getBusinesses({ change: busChange });
    ModuleFunctions.getClients({ change: clientsChange });
  }, []);

  if (create && clients) {
    return (
      <>
        {/* <AppHeaderComponent
          name="contracts"
          subpage="create"
          context={props.context}
          theme={props.context.theme}  
          saveBtn={handleSubmit}
        /> */}

        <div style={{ paddingTop: 1 }}>
          <ContractForm
            label="Create new Contract"
            type="create"
            context={props.context}
            route={props.route}
            showBtns={true}

            create={create}
            bus={bus}
            contract={contract}
            clients={clients}
            contacts={contacts}
            sites={sites}
            errors={errors}
            contractChange={contractChange}
            contactsChange={contactsChange}
            sitesChange={sitesChange}
          />
        </div>

        <ApiLoaderComponent
          status={loading.status}
          data={loading.data}
        />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
