import React from "react";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

export default function ModuleButton({
  text,
  stylename,
  btnIcon,
  onClick,
  style,
  size,
  tip,
  ...rest
}) {
  if (text && btnIcon) {
    return (
      <Tooltip title={tip}>
        <Button
          variant="contained"
          color={stylename}
          startIcon={<Icon>{btnIcon}</Icon>}
          onClick={onClick}
          style={style}
          size={size}
          {...rest}
        >
          {text}
        </Button>
      </Tooltip>
    );
  } else if (btnIcon) {
    return (
      <Tooltip title={tip}>
        <Button
          variant="contained"
          color={stylename}
          onClick={onClick}
          style={style}
          size={size}
          {...rest}
        >
          <Icon>{btnIcon}</Icon>
        </Button>
      </Tooltip>
    );
  } else if (text) {
    return (
      <Tooltip title={tip}>
        <Button
          variant="contained"
          color={stylename}
          onClick={onClick}
          style={style}
          size={size}
          {...rest}
        >
          {text}
        </Button>
      </Tooltip>
    );
  }
}
