const draftContract = {
  id: 20,
  ref: "C280623019",
  name: "Test Customer - this is a the test 2023-06-28",
  notes: null,
  author_uuid: "0626a24b-bed5-49b9-b091-805c72cd1d26",
  customer_id: "42485",
  account_no: "UA0189",
  company_reg: "NULL",
  billing_email: null,
  contact_id: "75636",
  contact_name: "Tim TesterX",
  site_id: "75570",
  site_name: "1, Brunel Road, Middlesbrough, TS6 6JA",
  deal_id: 154,
  customer_name: "Test Customer - this is a the test",
  customer_email: "NULL",
  customer_tel: "NULL",
  business_unit: null,
  status: "Draft",
  start_date: "28/06/2023",
  end_date: "28/12/2023",
  generated: false,
  generated_id: "75636",
  generated_date: "2023-06-28 14:26:34",
  generated_pdf_id: null,
  signed: false,
  signed_id: null,
  signed_date: null,
  signed_pdf_id: null,
  cancelled: false,
  cancelled_id: null,
  cancelled_date: null,
  previous_id: null,
};

const pendingContract = {
  id: 5,
  ref: "C130123004",
  name: "Test Customer - this is a the test 2023-01-13",
  notes: null,
  author_uuid: "0626a24b-bed5-49b9-b091-805c72cd1d26",
  customer_id: "42485",
  account_no: "UA0189",
  company_reg: "NULL",
  billing_email: null,
  contact_id: "75636",
  contact_name: "Tim Tester",
  site_id: "75570",
  site_name: "1, Brunel Road, Middlesbrough, TS6 6JA",
  deal_id: null,
  customer_name: "Test Customer - this is a the test",
  customer_email: "NULL",
  customer_tel: "NULL",
  business_unit: null,
  status: "Pending",
  start_date: "2023-01-13 00:00:00",
  end_date: "2024-01-13 00:00:00",
  generated: true,
  generated_id: "75636",
  generated_date: "2023-01-13 11:14:29",
  generated_pdf_id: null,
  signed: false,
  signed_id: null,
  signed_date: null,
  signed_pdf_id: null,
  cancelled: false,
  cancelled_id: null,
  cancelled_date: null,
  previous_id: null,
};

const activeContract = {
  id: 19,
  ref: "C280623018",
  name: "Test Customer - this is a the test 2023-06-28",
  deal_id: null,
  customer_name: "Test Customer - this is a the test",
  customer_email: "NULL",
  business_unit: null,
  status: "Active",
  start_date: "2023-06-28 00:00:00",
  end_date: "2024-02-28 00:00:00",
  generated_pdf_id: null,
  signed: true,
  signed_pdf_id: 1019,
  signed_date: "2024-06-04 12:11:58",
  overall_end: "2024-02-28 00:00:00",
};

const expiredContract = {
  id: 19,
  ref: "C280623018",
  name: "Test Customer - this is a the test 2023-06-28",
  deal_id: null,
  customer_name: "Test Customer - this is a the test",
  customer_email: "NULL",
  business_unit: null,
  status: "Active",
  start_date: "2023-06-28 00:00:00",
  end_date: "2024-02-28 00:00:00",
  generated_pdf_id: null,
  signed: true,
  signed_pdf_id: 1019,
  signed_date: "2024-06-04 12:11:58",
  overall_end: "2024-02-28 00:00:00",
};

const liveProduct = {
  id: 232,
  contract_id: 20,
  product_id: 148,
  product_ref: "PD240622003",
  deal_id: 154,
  name: "Cooking",
  description: null,
  notes: null,
  sbu_id: 26,
  prod_cat_id: 17,
  prod_group_id: 5,
  prod_line_id: 5,
  channel_id: 59,
  lines: [
    {
      material_id: null,
      material_ref: null,
      supplier_id: null,
      supplier_name: "Truck Suppliers",
      periodic_code_id: 16,
      minimum_term: 1,
      quantity: 12,
      markup: 9,
      unit_price: null,
      price: 674.8,
      options: [
        {
          in_basket: true,
          material_id: 12,
          material_name: "Wheel Nuts",
          material_ref: "TS-MAT-012",
          supplier_id: 42484,
          material_cost: "5",
          periodic_code_id: null,
          supplier_name: "Truck Suppliers",
          option_docs: [
            {
              id: 1034,
              name: "testingNEW",
              path: "9f630112-885b-49e2-9045-9376a57a33e7/15fc7c0d-df30-4288-9de4-01c4aca3302e/1717767351660",
              type: 4,
              raw_type: "text/plain",
              size: 0,
              tenant_ref: "9f630112-885b-49e2-9045-9376a57a33e7",
              user_ref: "15fc7c0d-df30-4288-9de4-01c4aca3302e",
              application_ref: null,
              created_by_name: "Kate Ramshaw",
              customer_id: null,
              public: false,
              created_at: "07-06-2024 13:35:52",
              updated_at: "07-06-2024 13:35:52",
              deleted_at: null,
            },
          ],
        },
      ],
      dynamic: true,
      component_id: 38,
      description: "Mudgaurds",
      unit_cost: 51.59,
      margin: 8,
      charge_code_id: 5,
      material_cost: "5",
    },
    {
      material_id: 12,
      material_ref: "TS-MAT-012",
      supplier_id: "42484",
      supplier_name: "Truck Suppliers",
      periodic_code_id: 18,
      minimum_term: 6,
      quantity: 2,
      markup: 15,
      unit_price: 15.61,
      price: 31.22,
      options: null,
      dynamic: false,
      component_id: 39,
      description: "Wheel Nuts",
      unit_cost: 13.57,
      margin: 13,
      charge_code_id: 5,
    },
    {
      material_id: 14,
      material_ref: "NPM-1",
      supplier_id: "42601",
      supplier_name: "NPM inc",
      periodic_code_id: 18,
      minimum_term: null,
      quantity: 1,
      markup: 17,
      unit_price: 8.73,
      price: 8.73,
      options: null,
      dynamic: false,
      component_id: 40,
      description: "package manager",
      unit_cost: 7.46,
      margin: 15,
      charge_code_id: 4,
    },
    {
      position_id: 5,
      position_name: "Developer 2.0",
      description: "Call out hourly rate",
      quantity: 2,
      periodic_code_id: 21,
      markup: 20,
      unit_price: 13.32,
      price: 26.64,
      labour_id: 38,
      unit_cost: 11.1,
      margin: 17,
      charge_code_id: 3,
    },
    {
      manual: true,
      charge_code_id: 7,
      periodic_code_id: 20,
      description: "Deposit retainer",
      unit_price: "30",
      price: 30,
      quantity: "1",
    },
  ],
  valid_from: "2024-06-10T23:00:00.000000Z",
  valid_to: "2025-06-17T23:00:00.000000Z",
  approved: false,
  approved_date: null,
  selected: false,
  displayed: false,
  responsible_id: "652d0a20f9d10f7973060102",
  lp_origin_id: null,
  lp_approval: false,
  active: true,
};

export {
  draftContract,
  pendingContract,
  activeContract,
  expiredContract,
  liveProduct,
};
