import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
} from "@mui/material";

import DataTable from "../common/DataTable";
import {
  ContractsApi,
  DealBuilderApi,
  LoadingComponent,
  StorageManager,
} from "@unity/components";
import { StatusChip } from "../common/statusChip";
import ModuleButton from "../common/ModuleButton";
import { draftContract } from "./MockData/dealData";
import { useTour } from "../services/TourContext";
import LoadingBackdrop from "../common/loadingBackdrop";

const pagination = true;
const cachedItem = "contract-draft-page-initial-data";

export default function DraftContracts({ context, read, readAll, bus }) {
  const tourOpen = useTour();

  const sm = new StorageManager();
  const initData = sm.getObject(cachedItem); // {page,pageSize,search,sort,filter}
  const localPage = initData?.page || null;
  const localPageSize = initData?.pageSize || null;
  const localSearch = initData?.search || null;
  const localSort = initData?.sort || null;
  const localFilter = initData?.filter || null;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [pdfRes, setPdfRes] = useState(null);
  const [page, setPage] = useState(localPage || 0);
  const [pageSize, setPageSize] = useState(localPageSize || 10);
  const [rowCount, setRowCount] = useState(0); // The total number of rows available from the search!
  const [searchText, setSearchText] = useState(localSearch || "");
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [currentContract, setCurrentContract] = useState(null);
  const [fetchTrigger, setFetchTrigger] = useState(0); // This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!
  const [sortModel, setSortModel] = useState(
    localSort
      ? [...localSort]
      : [
          {
            field: "",
            sort: "",
          },
        ]
  );
  const [filter, setFilter] = useState(
    localFilter
      ? { ...localFilter }
      : {
          items: [
            {
              columnField: "",
              operatorValue: "",
              value: "",
            },
          ],
        }
  );

  const history = useHistory();

  useEffect(() => {
    const getContracts = async () => {
      const res = await ContractsApi.getDraftContracts({
        read_all: readAll,
        pagination,
        page: page + 1,
        per_page: pageSize,
        search_term: searchText,
        sort_name: sortModel[0]?.field,
        sort_direction: sortModel[0]?.sort,
        filter: filter.items[0],
      });
      if (res.success) {
        setData(res.data);
        if (pagination) {
          setRowCount(res.meta.total);
        }
      } else {
        // the request has failed, load an empty array!
        setData([]);
      }
    };
    if (tourOpen) {
      setData([draftContract]);
    } else {
      getContracts();
    }
  }, [fetchTrigger]);

  const checkProducts = (products) => {
    console.log(products);
    try {
      if (Array.isArray(products) && products.length > 0) {
        return products.every((prod) => {
          if (Array.isArray(prod.lines)) {
            return prod.lines.every((el) => {
              console.log(
                "lines k?? ",
                el.dynamic ? el?.options.some((op) => op.in_basket) : ""
              );
              return el.dynamic ? el.options.some((op) => op.in_basket) : true;
            });
          } else {
            return true;
          }
        });
      } else {
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const generatePdf = async () => {
    try {
      setLoading(true);
      const productRef = await DealBuilderApi.getProductsByContract(
        currentContract?.id
      );
      if (productRef?.success && checkProducts(productRef.data)) {
        const res = await ContractsApi.createVer2({
          ...currentContract,
          contract_id: currentContract?.id,
          variation: false,
          products: productRef.success ? productRef.data : [],
        });
        setPdfRes(res);
      } else {
        setPdfRes({
          success: false,
          errors: { message: "You must add at least one product line option" },
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const deleteContract = async () => {
    setLoading(true);
    const res = await ContractsApi.deleteContract(currentContract?.id);
    setLoading(false);
    handleClose();
  };

  const handleSearchTextChange = (value) => {
    setSearchText(value);
    sm.setObject(cachedItem, { ...initData, search: value });
    setFetchTrigger(fetchTrigger + 1);
  };

  const handleSort = (sort) => {
    setSortModel(sort);
    sm.setObject(cachedItem, { ...initData, sort });
    setFetchTrigger(fetchTrigger + 1);
  };

  const handleFilter = (filter) => {
    setFilter(filter);
    sm.setObject(cachedItem, { ...initData, filter });
    setFetchTrigger(fetchTrigger + 1);
  };

  const handleGenerateClick = (data) => {
    const localContract = JSON.parse(JSON.stringify(data));
    setOpen(true);
    setCurrentContract(localContract);
  };

  const handleDeleteClick = (data) => {
    const localContract = JSON.parse(JSON.stringify(data));
    setDeleteOpen(true);
    setCurrentContract(localContract);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteOpen(false);
    setCurrentContract(null);
    setPdfRes(null);
    setFetchTrigger(fetchTrigger + 1);
  };

  const handlePageChange = (page) => {
    setPage(page);
    sm.setItem(cachedItem, { ...initData, page });
    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);
    sm.setItem(cachedItem, { ...initData, pageSize: size });
    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleViewClick = (event, params, path) => {
    event.preventDefault();
    history.push(`/contracts/${path}/${params.id}`);
  };

  const columns = [
    {
      field: "ref",
      headerName: "Ref#",
      minWidth: 120,
      renderCell: (cellValues) => (
        <Tooltip title={cellValues.row.ref}>
          <span>{cellValues.row.ref}</span>
        </Tooltip>
      ),
    },
    {
      field: "contract_name",
      valueGetter: (params) => params.row.name,
      headerName: "Name",
      flex: 0.3,
    },
    {
      field: "contract_start",
      valueGetter: (params) => params.row.start_date,
      headerName: "Start",
      width: 190,
    },
    {
      field: "sbu_unit",
      headerName: "Business Unit",
      flex: 0.1,
      renderCell: (cellValues) => {
        if (cellValues.row.business_unit) {
          const pc = bus.find((e) => cellValues.row.business_unit === e.id);
          return (
            <Tooltip title={pc.name}>
              <span>{pc.name}</span>
            </Tooltip>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "customer_name",
      headerName: "Customer",
      flex: 0.2,
      renderCell: (cellValues) => (
        <Tooltip title={cellValues.row.customer_name}>
          <span>{cellValues.row.customer_name}</span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 80,
      renderCell: (cellValues) => <StatusChip status={cellValues.row.status} />,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 300,
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div className="tour-contract-draft-actions">
            <ModuleButton
              btnIcon="edit"
              style={{
                background: "orange",
                color: "white",
                marginLeft: 10,
              }}
              onClick={(event) =>
                handleViewClick(event, cellValues.row, "edit")
              }
              tip="Edit contract"
            />
            <ModuleButton
              btnIcon="publish"
              style={{
                background: "green",
                color: "white",
                marginLeft: 10,
              }}
              onClick={() => handleGenerateClick(cellValues.row)}
              tip="create pdf contract"
            />
            <ModuleButton
              btnIcon="delete"
              style={{
                background: "red",
                color: "white",
                marginLeft: 10,
              }}
              onClick={() => handleDeleteClick(cellValues.row)}
              tip="Delete contract"
            />
          </div>
        );
      },
    },
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680,
  };

  if (read && data) {
    return (
      <>
        <div className="tour-contract-sort">
          <DataTable
            columns={columns}
            data={data}
            pagination
            options={options}
            allowDelete={false}
            searchText={searchText}
            setSearchText={handleSearchTextChange}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
            sortModel={sortModel}
            setSortModel={handleSort}
            filter={filter}
            setFilter={handleFilter}
          />
        </div>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogContent>
            <DialogContentText>
              {!pdfRes
                ? "Generate pdf and set contract as pending?"
                : pdfRes.success
                ? "The pdf has been successfully created"
                : "There was a problem with your request"}
            </DialogContentText>
            {pdfRes?.errors
              ? Object.entries(pdfRes.errors).map((err) => (
                  <DialogContentText>{`${err[0]}: ${err[1]}`}</DialogContentText>
                ))
              : null}
          </DialogContent>
          <DialogActions>
            {!pdfRes ? (
              <>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={generatePdf}>
                  Generate Contract
                </Button>
              </>
            ) : (
              <Button variant="contained" onClick={handleClose}>
                Close
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Dialog open={deleteOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogContent>
            <DialogContentText>Delete Contract</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
            <Button variant="contained" color="error" onClick={deleteContract}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <LoadingBackdrop open={loading} onClose={() => setLoading(false)} />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
