import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

import { DocumentVaultAPI, ApiLoaderComponent } from "@unity/components";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#34884c",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  height: 200,
  width: "100%",
};

export default function DropzoneModule({
  open,
  setOpen,
  onClose,
  onUpdate,
  customContent,
  readOnly,
}) {
  const [files, setFiles] = useState([]);
  const [droppedFile, setDroppedFile] = useState(null);
  const [loading, setLoading] = useState({ status: false });

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const extIndex = file.name.lastIndexOf(".");
        const extension = file.name.substring(extIndex);
        const name = file.name.substring(0, extIndex);
        setDroppedFile({
          base64: e.target.result,
          name,
          extension,
          type: file.type,
          size: file.size,
        });
      };
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 5,
    accept: {
      "text/plain": [".txt"],
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      "application/msword": [".doc", ".docx"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/pdf": [".pdf"],
      "video/mp4": [".mp4"],
    },
  });

  useEffect(() => {
    if (droppedFile) {
      setFiles((prev) => [...prev, JSON.parse(JSON.stringify(droppedFile))]);
    }
  }, [droppedFile]);

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  const storeDocuments = async () => {
    setLoading({ status: true });
    try {
      const filePromises = files.map((el) =>
        DocumentVaultAPI.storeDocument({
          documents: [el],
        })
      );
      Promise.all(filePromises).then((res) => {
        const check = res.find((el) => !el.success);
        if (!check) {
          const docIds = res.map((el) => {
            return el.data;
          });
          onUpdate(docIds.flat());
        }
      });
    } catch (err) {
      console.log(err);
    }
    setLoading({ status: false });
    onClose ? onClose() : setOpen(false);
    setFiles([]);
    setDroppedFile(null);
  };

  const handleDeleteFile = (file) => {
    let localFiles = [...files];
    setFiles(localFiles.filter((el) => el.name !== file));
  };

  const getChip = (data) => {
    return (
      <Chip
        color="primary"
        label={data?.name || "test"}
        onDelete={() => handleDeleteFile(data.name)}
      />
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => (onClose ? onClose() : setOpen(false))}
      maxWidth="md"
      fullWidth
    >
      {!readOnly ? (
        <DialogContent>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>{"Drag 'n' drop some files here, or click to select files"}</p>
            )}
          </div>
          {files && Array.isArray(files) ? (
            <div style={{ marginTop: 30 }}>
              <Typography>Files to upload:</Typography>
              <Grid container spacing={1}>
                {files.map((el, i) => (
                  <Grid item key={i}>
                    {getChip(el)}
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : null}
        </DialogContent>
      ) : null}
      {customContent ? <DialogContent>{customContent}</DialogContent> : null}
      <DialogActions>
        <Button onClick={() => (onClose ? onClose() : setOpen(false))}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={storeDocuments}
          disabled={readOnly}
        >
          Upload
        </Button>
      </DialogActions>
      <ApiLoaderComponent status={loading.status} />
    </Dialog>
  );
}
