export const getSiteAddress = ({
  house_name = "",
  house_no = "",
  street = "",
  area = "",
  town = "",
  county = "",
  country = "",
  postcode = "",
} = {}) => {
  const address = `${house_name || ""} ${house_no || ""} ${street || ""} ${
    area || ""
  } ${town || ""} ${county || ""} ${country || ""} ${postcode || ""}`;
  return address.trim();
};
