import { Chip } from "@mui/material";
import React from "react";

const DRAFT = "Draft";
const APPROVAL = "Pending";
const ACTIVE = "Active";
const EXPIRED = "Expired";
const INACTIVE = "Cancelled/Rejected";

const getStatusColor = (status) => {
  switch (status) {
    case DRAFT:
      return "#ffeb3b";
    case APPROVAL:
      return "#ff9800";
    case ACTIVE:
      return "#8bc34a";
    case EXPIRED:
      return "#e57373";
    case INACTIVE:
      return "#bdbdbd";
  }
};

export const StatusChip = ({ status }) => {
  return (
    <Chip
      label={status}
      sx={{ bgcolor: getStatusColor(status), fontWeight: "bold" }}
    />
  );
};
