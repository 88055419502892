import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

export default function StaticProduct({ error, ...props }) {
  const [loading, setLoading] = useState({ status: true });
  const [data, setData] = useState(false);

  const uneditable = false;

  function handleChange({ name, value }) {
    let localData = JSON.parse(JSON.stringify(data));
    localData[name] = value;
    setData(localData);
  }

  const handleSubmit = async () => {
    let products = props.data;
    let product = products[props.productIndex];
    product.lines[lineIndex] = data;
    props.updateProduct(product);
    props.handleChange(products);
    props.handleDialog();
  };

  const loadInitData = async () => {
    setLoading({
      status: true,
      data: "Loading Contract Form, Please Wait....",
    });
    setData(props.data[props.productIndex].lines[props.lineIndex]);
    setLoading({ status: false, data: "" });
  };

  useEffect(() => {
    loadInitData();
  }, [props.open]);

  if (!props.open) {
    return null;
  }

  const handleClose = () => {
    setData(false);
    props.setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={props.open}
      onClose={props.handleDialog}
    >
      <DialogTitle>{props.label}</DialogTitle>

      <DialogContent>
        {loading.status ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <br />
            <span>{loading.data}</span>
          </div>
        ) : (
          <>
            <h2>General</h2>
            <div className="form-row">
              <div className="form-group col-lg-12">
                <TextField
                  label="Name"
                  name="material_name"
                  type="text"
                  value={data.material_name || ""}
                  error={(error && error.name) || false}
                  helperText={(error && error.name) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>
              <div className="form-group col-lg-12">
                <TextField
                  label="Ref#"
                  name="ref"
                  type="text"
                  value={data.material_ref || ""}
                  size="small"
                  fullWidth
                  disabled
                ></TextField>
              </div>
            </div>

            <h2>Pricing</h2>
            <div className="form-row">
              <div className="form-group col-lg-12">
                <TextField
                  label="Material Cost"
                  name="material_cost"
                  type="number"
                  value={data.material_cost || ""}
                  error={(error && error.name) || false}
                  helperText={(error && error.name) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>

              <div className="form-group col-lg-12">
                <TextField
                  label="Quantity"
                  name="quantity"
                  type="number"
                  value={data.quantity || ""}
                  error={(error && error.name) || false}
                  helperText={(error && error.name) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>
            </div>

            <h2>Summary</h2>
            <div className="form-row">
              <div className="form-group col-lg-4">
                <TextField
                  label="Total Price"
                  type="text"
                  value={data.material_cost * data.quantity || ""}
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                ></TextField>
              </div>
              <div className="form-group col-lg-4">
                <TextField
                  label="Periodic Code"
                  name="account_no"
                  type="text"
                  value={data.periodic_code_id || ""}
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                ></TextField>
              </div>
              <div className="form-group col-lg-4">
                <TextField
                  label="Charge Code"
                  name="account_no"
                  type="text"
                  value={data.charge_code_id || ""}
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: true }}
                ></TextField>
              </div>
            </div>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: "#2100b7" }}
          onClick={() => handleSubmit()}
        >
          SAVE
        </Button>
        <Button
          variant="outlined"
          size="large"
          style={{ border: "1px solid #2100b7", color: "#2100b7" }}
          onClick={() => handleClose()}
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}
