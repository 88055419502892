import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { DealBuilderApi, CodesApi } from "@unity/components";
import ProductTable from "./ProductTable";
import { CircularProgress, Typography } from "@mui/material";
import { useTour } from "../services/TourContext";
import { liveProduct } from "./MockData/dealData";

export default function ProductSection(props) {
  const { id } = useParams();
  const tourOpen = useTour();

  const readOnly = props.type === "view";

  const [loading, setLoading] = useState(true);
  const [liveProducts, setLiveProducts] = useState(false); //The current live products attached to deal
  const [triggerChange, setTriggerChange] = useState(0);
  const [deal, setDeal] = useState(false);

  const [chargeCodes, setChargeCodes] = useState(false);
  const [periodicCodes, setPeriodicCodes] = useState(false);

  const handleTriggerChange = () => {
    setTriggerChange(triggerChange + 1);
  };

  const handleChange = (newData) => {
    setLiveProducts(newData);
    handleTriggerChange();
  };

  const getDeal = async () => {
    const res = await DealBuilderApi.getDealByContract(id);
    if (res.success) {
      setDeal(res.data);
    }
  };

  const getProducts = async () => {
    let res = await DealBuilderApi.getProductsByContract(id);
    if (res.success) {
      setLiveProducts(res.data);
    }
  };

  const getData = async () => {
    const Pres = await CodesApi.getPeriodicCodes();
    if (Pres.success) {
      setPeriodicCodes(Pres.data);
    }
    const Cres = await CodesApi.getChargeCodes();
    if (Cres.success) {
      setChargeCodes(Cres.data);
    }
  };

  const initData = async () => {
    setLoading(true);
    await getData();
    await getDeal();
    if (tourOpen) {
      setLiveProducts([liveProduct]);
    } else {
      await getProducts();
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [triggerChange]);

  return loading ? (
    <div style={{ display: "flex", gap: 10 }}>
      <Typography>Loading Products</Typography>
      <CircularProgress />
    </div>
  ) : (
    <div className="block tour-contract-products">
      <div className="container-fluid">
        <ProductTable
          contractId={id}
          products={liveProducts}
          deal={deal}
          handleChange={handleChange}
          triggerChange={triggerChange}
          chargeCodes={chargeCodes}
          periodicCodes={periodicCodes}
          handleTriggerChange={handleTriggerChange}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}
