import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "../common/ModuleFunctions";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ServiceLines from "./ServiceLines";

export default function OveVariationForm(props) {
  const uneditable = ((props.type === 'view') || !props[props.type]);

  if(props.variation.customer_id) {
    ModuleFunctions.setClientChildren({
      id: props.variation.customer_id,
      clients: props.clients,
      contactsChange: props.contactsChange,
      sitesChange: props.sitesChange
    });
  }


  return (
    <div>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Overall Variation</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Requested By"
                name="requested_by"
                type="text"
                value={props.variation.requested_by || ""}
                error={props.errors && props.errors.requested_by || false}
                helperText={props.errors && props.errors.requested_by || ""}
                onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.variationChange })}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
            <FormControl error={props.errors && props.errors.how_received || false} fullWidth>
                <InputLabel>Request Format</InputLabel>
                <Select
                  label="Request Format"
                  name="how_received"
                  value={props.variation.how_received || ""}
                  onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.variationChange })}
                  inputProps={{ readOnly: uneditable }}
                  size="small"
                >
                  {props.reasons && props.reasons.map((item, key) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
                <FormHelperText>{props.errors && props.errors.how_received || ""}</FormHelperText>
              </FormControl>
            </div>
            <div className="form-group col-lg-4">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Requested Date"
                  value={props.variation.requested_date || ""}
                  onChange={(value) => uneditable || ModuleFunctions.handleChange({ name: 'requested_date', value: value, change: props.variationChange })}
                  inputProps={{ readOnly: uneditable }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      error={props.errors && props.errors.requested_date || false}
                      helperText={ props.errors && props.errors.requested_date || "" }
                      size="small"
                      fullWidth
                    />
                  }
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-8">
              <TextField
                label="Name"
                name="name"
                type="text"
                value={props.variation.name || ""}
                error={props.errors && props.errors.name || false}
                helperText={ props.errors && props.errors.name || "" }
                onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.variationChange })}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <TextField
                label="Variation#"
                name="ref"
                type="text"
                value={props.variation.ref || ""}
                size="small"
                fullWidth
                disabled
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="Outline"
                name="outline"
                type="text"
                value={props.variation.outline || ""}
                multiline
                minRows={2}
                maxRows={5}
                error={props.errors && props.errors.notes || false}
                helperText={props.errors && props.errors.notes || ""}
                onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.variationChange })}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Customer Name"
                name="customer_name"
                type="text"
                value={props.variation.customer_name || ""}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              />
            </div>
            {props.type !== "view" && props.contacts ? (
              <div className="form-group col-lg-4">
                <FormControl error={props.errors && props.errors.contact_id || false} fullWidth>
                  <InputLabel>Contact Name</InputLabel>
                  <Select
                    label="Contact Name"
                    name="contact_id"
                    value={props.variation.contact_id || ""}
                    onChange={(evt, personObj) => uneditable || ModuleFunctions.handlePersonChange({
                      event: evt,
                      element: personObj,
                      change: props.variationChange
                    })}
                    inputProps={{ readOnly: uneditable }}
                    size="small"
                  >
                    {props.contacts && props.contacts.map((item, key) => {
                      return <MenuItem key={key} value={item.contact_id} personObj={item}>{item.contact_name}</MenuItem>
                    })}
                  </Select>
                  <FormHelperText>{props.errors && props.errors.contact_id || ""}</FormHelperText>
                </FormControl>
              </div>
            ) : (
              <div className="form-group col-lg-4">
                <TextField
                  label="Contact Name"
                  name="contact_name"
                  value={props.variation.contact_name || ""}
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                />
              </div>
            )}
            {props.type !== "view" && props.sites ? (
              <div className="form-group col-lg-4">
                <FormControl error={props.errors && props.errors.site_id || false} fullWidth>
                  <InputLabel>Company Address</InputLabel>
                  <Select
                    label="Company Address"
                    name="site_id"
                    value={props.variation.site_id || ""}
                    onChange={(evt, siteObj) => uneditable || ModuleFunctions.handleSiteChange({
                      event: evt,
                      element: siteObj,
                      change: props.variationChange
                    })}
                    inputProps={{ readOnly: uneditable }}
                    size="small"
                  >
                    {props.sites && props.sites.map((item, key) => {
                      return <MenuItem key={key} value={item.site_id} siteObj={item}>{item.site_name}</MenuItem>
                    })}
                  </Select>
                  <FormHelperText>{props.errors && props.errors.site_id || ""}</FormHelperText>
                </FormControl>
              </div>
            ) : (
              <div className="form-group col-lg-4">
                <TextField
                  label="Company Address"
                  name="site_name"
                  value={props.variation.site_name || ""}
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                />
              </div>
            )}
          </div>

          <div className="form-row">
            <div className="form-group col-lg-6">
              <TextField
                label="Company Reg#"
                name="company_reg"
                type="text"
                value={props.variation.company_reg || ""}
                error={props.errors && props.errors.comnpany_reg || false}
                helperText={ props.errors && props.errors.company_reg || "" }
                onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.variationChange })}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              ></TextField>
            </div>
            <div className="form-group col-lg-6">
              <TextField
                label="Billing Email"
                name="billing_email"
                type="email"
                value={props.variation.billing_email || ""}
                error={props.errors && props.errors.billing_email || false}
                helperText={ props.errors && props.errors.billing_email || "" }
                onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.variationChange })}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Account#"
                name="account_no"
                type="text"
                value={props.variation.account_no || ""}
                size="small"
                fullWidth
                disabled
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <TextField
                label="Email"
                name="customer_email"
                type="email"
                value={props.variation.customer_email || ""}
                error={props.errors && props.errors.customer_email || false}
                helperText={ props.errors && props.errors.customer_email || "" }
                onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.variationChange })}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <TextField
                label="Tel"
                name="customer_tel"
                type="tel"
                value={props.variation.customer_tel || ""}
                error={props.errors && props.errors.customer_tel || false}
                helperText={ props.errors && props.errors.customer_tel || "" }
                onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.variationChange })}
                size="small"
                fullWidth
                InputProps={{ readOnly: uneditable }}
              ></TextField>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <FormControl error={props.errors && props.errors.business_unit || false} fullWidth disabled>
                <InputLabel>Business Unit</InputLabel>
                <Select
                  label="Business Unit"
                  name="business_unit"
                  value={props.variation.business_unit || ""}
                  onChange={(e) => uneditable || ModuleFunctions.handleChange({ name: e.target.name, value: e.target.value, change: props.variationChange })}
                  // inputProps={{ readOnly: uneditable }}
                  size="small"
                >
                  {props.bus && props.bus.map((item, key) => {
                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                  })}
                </Select>
                <FormHelperText>{props.errors && props.errors.business_unit || ""}</FormHelperText>
              </FormControl>
            </div>
            <div className="form-group col-lg-4">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={props.variation.start_date || ""}
                  onChange={(value) => uneditable || ModuleFunctions.handleChange({ name: 'start_date', value: value, change: props.variationChange })}
                  inputProps={{ readOnly: uneditable }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      error={props.errors && props.errors.start_date || false}
                      helperText={ props.errors && props.errors.start_date || "" }
                      size="small"
                      fullWidth
                    />
                  }
                />
              </LocalizationProvider>
            </div>
            <div className="form-group col-lg-4">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  value={props.variation.end_date || ""}
                  onChange={(value) => uneditable || ModuleFunctions.handleChange({ name: 'end_date', value: value, change: props.variationChange })}
                  inputProps={{ readOnly: uneditable }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      error={props.errors && props.errors.end_date || false}
                      helperText={ props.errors && props.errors.end_date || "" }
                      size="small"
                      fullWidth
                    />
                  }
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group-sm col-lg-12">
              <ServiceLines
                uneditable={uneditable}
                parent={props.variation}
                deals={props.deals}
                periods={props.periods}
                charges={props.charges}
                methods={props.methods}
                vats={props.vats}
                parentChange={props.variationChange}
                triggerChange={props.triggerChange}
              />
            </div>
          </div>

        </div>
      </div>


      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>History</h3>
            </div>
          </div>

          {props.records &&
            props.records.map((document, index) => {
              return (
                <div className="block">
                  <h4>{document.ref}</h4>

                  <div
                    className="form-row"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="form-group col-lg-6">
                      <TextField
                        label="Document Name"
                        name="name"
                        value={document.name || ""}
                        disabled
                        fullWidth={true}
                      ></TextField>
                    </div>
                    <div className="form-group col-lg-4">
                      <TextField
                        label="Status"
                        name="status"
                        value={document.status || ""}
                        disabled
                        fullWidth={true}
                      ></TextField>
                    </div>
                    <div className="form-group col-lg-2">
                      <div onClick={() => ModuleFunctions.handleView({ contract: document })}>
                        <ModuleButton
                          text="View"
                          btnIcon="view"
                          stylename="secondary"
                          style={{ float: "right" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
