import React, { useState, useEffect } from "react";
import { ContractsApi, LoadingComponent, AppHeaderComponent, ApiLoaderComponent } from "@unity/components";
import { useHistory } from "react-router";
import { CanCreate } from "../../services/Permissions";
import VariationTypeForm from "../../modules/VariationTypeForm";
import ModuleFunctions from "../../common/ModuleFunctions";

export default function Variations(props) {
  const [data, setData] = useState(false);
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState({ status: false });

  let history = useHistory();

  const create = CanCreate("contracts");

  const dataChange = (data) => {
    setData(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const handleAddService = async (id, obj) => {
    const newObj = obj;
    newObj['variation_id'] = id;
    await ContractsApi.saveService(newObj);
  };

  const handleSubmit = async () => {
    setLoading({ status: true, data: "Creating your Variation, Please Wait...." });

    const newData = { ...data };
    if(!newData.overall_variation) {
      delete newData['services'];
    }

    const res = await ContractsApi.saveVariation(newData);

    console.log("Variation Save Res",res);

    if(res.success) {
      if(Array.isArray(data.services)) {
        const unresolvedPromises = data.services.map(element => handleAddService(res.data.id, element));

        await Promise.all(unresolvedPromises).then(success => {
          setLoading({ status: true, data: "Succssfully added contract variation" });
        }).catch(error => {
          setLoading({ status: true, data: `Unable to assign product line with id: ${error} to contract variation` });
        });
      }
      
      setTimeout(() => {
        history.push({
          pathname: `/contracts/variations/edit/${res.data.id}`,
          state: { hasErrors: false }
      });
        setLoading({ status: false });
      }, 3000);
    } else {
      setLoading({ status: true, data: res.message });
      setTimeout(() => {
          history.push("/contracts/index");
          setLoading({ status: false });
      }, 3000);
    }
  };


  useEffect(() => {
    ModuleFunctions.createVariation({ id: props.route.location.state.id, change: dataChange });
  }, []);



  if (data && create) {
    return (
      <>
        <AppHeaderComponent
          context={props.context}
          theme={props.context.theme}
          saveBtn={selected ? handleSubmit : null}
          name={"contracts"}
          subpage={"variation"}
        />

        <div style={{ paddingTop: 1 }}>
          <VariationTypeForm
            context={props.context}
            data={data}
            dataChange={dataChange}
            selected={selected}
            setSelected={setSelected}
          />
        </div>

        <ApiLoaderComponent
          status={loading.status}
          data={loading.data}
        />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
