import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

export default function LoadingBackdrop({ open, handleClose }) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 30000 }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress />
    </Backdrop>
  );
}
