import React, { useEffect, useState } from "react";
import {
  TextField,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  ContractsApi,
  BusinessApi,
  DatePickerComponent,
} from "@unity/components";
import { useHistory } from "react-router";
import { getContactName } from "../utils/getContactName";
import { getSiteAddress } from "../utils/getSiteAddress";
import { getFormattedDate } from "../utils/getFormattedDate";
import { useTour } from "../services/TourContext";

export default function ContractForm({
  type,
  contract: initialContract,
  open,
  setOpen,
  handleDialog,
  label,
}) {
  const [contract, setContract] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [errors, setErrors] = useState(false);

  const [businesses, setBusinesses] = useState([]);
  const [sites, setSites] = useState([]);
  const [contacts, setContacts] = useState([]);

  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);

  const [businessSearchTerm, setBusinessSearchTerm] = useState("");
  const [busOpen, setBusOpen] = useState(false);
  const load = busOpen; //&& businesses.length === 0;

  const uneditable = false;
  let history = useHistory();
  const tourOpen = useTour();

  useEffect(() => {
    const getBusinesses = async () => {
      const res = await BusinessApi.businessIndexFiltered({
        rules:
          businessSearchTerm == null || businessSearchTerm === ""
            ? []
            : [
                {
                  field: "customer_name",
                  operator: "LIKE",
                  value: `%${businessSearchTerm}%`,
                },
                { field: "is_client", operator: "=", value: true },
              ],
        page: 1,
      });
      if (res && res.success) setBusinesses(res.data.data);
    };
    let active = true;
    if (!load) {
      return undefined;
    }
    if (active) {
      getBusinesses();
    }
    return () => {
      active = false;
    };
  }, [businessSearchTerm, load]);

  useEffect(() => {
    if (!busOpen) {
      setBusinesses([]);
    }
  }, [busOpen]);

  useEffect(() => {
    const getSites = async () => {
      const res = selectedContact
        ? await BusinessApi.siteShow(selectedContact?._site_id)
        : await BusinessApi.siteIndexForBusiness(selectedBusiness?._id);
      if (res && res.success) {
        setSites(selectedContact ? [res.data] : res.data.data);

        if (selectedContact && res.data._id !== initialContract?.site_id) {
          setSelectedSite(res.data);
          handleChange([
            {
              name: "site_name",
              value: getSiteAddress(res.data),
            },
            { name: "site_id", value: res.data?._id },
          ]);
        }
      }
    };
    if (selectedBusiness) getSites();
  }, [selectedContact, selectedBusiness]);

  useEffect(() => {
    const getContacts = async () => {
      const res = selectedSite
        ? await BusinessApi.contactIndexForSite(selectedSite?._id)
        : await BusinessApi.contactIndexForBusiness(selectedBusiness?._id);
      if (res && res.success) {
        setContacts(res.data.data);
        if (
          selectedSite &&
          contract?.contact_id !== selectedContact?._id &&
          res.data.data[0]._id !== initialContract?.contact_id
        ) {
          setSelectedContact(res.data.data[0]);
          handleChange([
            {
              name: "contact_name",
              value: getContactName(res.data.data[0]),
            },
            { name: "contact_id", value: res.data.data[0]?._id },
          ]);
        }
      }
    };
    if (selectedBusiness) getContacts();
  }, [selectedSite, selectedBusiness]);

  const contractChange = (data) => {
    setContract((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  function handleChange(data) {
    const newData = {};
    newData["changed"] = true;
    if (Array.isArray(data)) {
      data.forEach((el) => {
        newData[el.name] = el.value;
      });
    } else {
      newData[data.name] = data.value;
    }

    contractChange(newData);
  }

  const handleSubmit = async () => {
    let res;
    if (type && type === "create") {
      console.log("saving new contract: ", contract);

      res = await ContractsApi.saveContract({ ...contract });
      //generate deal fro contract here? check if deal exists
    } else if (type && type === "edit") {
      res = await ContractsApi.updateContract(contract.id, contract);
    }

    if (res?.success) {
      setLoading({ status: true, data: "Successfully created Contract" });
      setTimeout(() => {
        history.push(`/contracts/edit/${res.data.id}`);
        setLoading({ status: false });
      }, 3000);
    } else {
      if (res?.errors) {
        setErrors(res.errors);
        setLoading({
          status: true,
          data: "Validation Errors!, Please Wait....",
        });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res?.message });
        setTimeout(() => {
          handleClose();
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  const loadInitData = async () => {
    setLoading({
      status: true,
      data: "Loading Contract Form, Please Wait....",
    });
    if (type == "edit" && initialContract) {
      setContract(initialContract);
    }
    const getContact = async () => {
      const res = await BusinessApi.contactShow(initialContract?.contact_id);
      if (res && res.success) setSelectedContact(res.data);
    };
    const getSite = async () => {
      const res = await BusinessApi.siteShow(initialContract?.site_id);
      if (res && res.success) setSelectedSite(res.data);
    };
    const getBusiness = async () => {
      const res = await BusinessApi.businessIndexFiltered({
        rules: [
          {
            field: "customer_name",
            operator: "LIKE",
            value: initialContract?.customer_name,
          },
        ],
        page: 1,
      });
      if (res && res.success) setSelectedBusiness(res.data?.data[0] || null);
    };
    if (initialContract?.customer_name) getBusiness();
    if (initialContract?.site_id) getSite();
    if (initialContract?.contact_id) getContact();
    setLoading({ status: false, data: "" });
  };

  useEffect(() => {
    loadInitData();
  }, [open]);

  if (!open) {
    return null;
  }

  const handleClose = () => {
    setContract(false);
    setSelectedBusiness(null);
    setSelectedContact(null);
    setSelectedSite(null);
    setOpen(false);
    setErrors(null);
    if (tourOpen) {
      window.dispatchEvent(
        new CustomEvent("tourStepIndex", { detail: { stepIndex: 1 } })
      );
    }
  };

  return (
    <Dialog
      className="tour-contract-form-edit"
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={handleDialog}
    >
      <DialogTitle>{label}</DialogTitle>

      <DialogContent>
        {loading.status ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <br />
            <span>{loading.data}</span>
          </div>
        ) : (
          <>
            <div className="form-row">
              <div className="form-group col-lg-8">
                <TextField
                  label="Name"
                  name="name"
                  type="text"
                  value={contract.name || ""}
                  error={(errors && errors.name) || false}
                  helperText={(errors && errors.name) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>
              <div className="form-group col-lg-4">
                <TextField
                  label="Ref#"
                  name="ref"
                  type="text"
                  value={contract.ref || ""}
                  size="small"
                  fullWidth
                  disabled
                ></TextField>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-lg-12">
                <TextField
                  label="Notes"
                  name="notes"
                  type="text"
                  value={contract.notes || ""}
                  multiline
                  minRows={2}
                  maxRows={5}
                  error={(errors && errors.notes) || false}
                  helperText={(errors && errors.notes) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>
            </div>

            <div className="form-row tour-contract-business-form">
              {type !== "view" && businesses ? (
                <div className="form-group col-lg-4">
                  <Autocomplete
                    open={busOpen}
                    onOpen={() => {
                      setBusOpen(true);
                    }}
                    onClose={() => {
                      setBusOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    filterOptions={(x) => x}
                    getOptionLabel={(option) => option.customer_name || ""}
                    options={businesses || []}
                    value={selectedBusiness}
                    onChange={(e, val) => {
                      setSelectedBusiness(val);

                      handleChange([
                        {
                          name: "customer_name",
                          value: val?.customer_name,
                        },
                        {
                          name: "customer_id",
                          value: val?._id,
                        },
                        {
                          name: "account_no",
                          value: val?.account_no,
                        },
                        {
                          name: "company_reg",
                          value: val?.company_number,
                        },
                        {
                          name: "billing_email",
                          value: val?.billing_email,
                        },
                      ]);
                      setSelectedSite(null);
                      setSelectedContact(null);
                    }}
                    inputValue={businessSearchTerm}
                    onInputChange={(e, input) => {
                      setBusinessSearchTerm(input);
                    }}
                    loading={load}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Business"
                        error={(errors && errors.customer_id) || false}
                        helperText={(errors && errors.customer_id) || ""}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {load ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    size="small"
                    fullWidth
                  />
                </div>
              ) : null}
              {type !== "view" && contacts ? (
                <div className="form-group col-lg-4">
                  <Autocomplete
                    disabled={!Boolean(selectedBusiness)}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    getOptionLabel={(option) => getContactName(option) || ""}
                    options={contacts || []}
                    value={selectedContact}
                    onChange={(e, val) => {
                      setSelectedContact(val);
                      console.log("contact: ", val);
                      handleChange([
                        {
                          name: "contact_name",
                          value: getContactName(val),
                        },
                        { name: "contact_id", value: val?._id },
                        {
                          name: "customer_email",
                          value: val?.email,
                        },
                        {
                          name: "customer_tel",
                          value:
                            val.mobile || val.phone || val?.other_phone || "",
                        },
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Contact"
                        error={(errors && errors.contact_id) || false}
                        helperText={(errors && errors.contact_id) || ""}
                      />
                    )}
                    size="small"
                    fullWidth
                  />
                </div>
              ) : null}
              {type !== "view" && sites ? (
                <div className="form-group col-lg-4">
                  <Autocomplete
                    disabled={!Boolean(selectedBusiness)}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    getOptionLabel={(option) => getSiteAddress(option) || ""}
                    options={sites || []}
                    value={selectedSite}
                    onChange={(e, val) => {
                      console.log("site???? ");
                      setSelectedSite(val);
                      handleChange([
                        {
                          name: "site_name",
                          value: getSiteAddress(val),
                        },
                        { name: "site_id", value: val?._id },
                      ]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Site"
                        error={(errors && errors.site_id) || false}
                        helperText={(errors && errors.site_id) || ""}
                      />
                    )}
                    size="small"
                    fullWidth
                  />
                </div>
              ) : null}
            </div>

            <div className="form-row">
              <div className="form-group col-lg-6">
                <TextField
                  label="Company Reg#"
                  name="company_reg"
                  type="text"
                  value={contract.company_reg || ""}
                  error={(errors && errors.company_reg) || false}
                  helperText={(errors && errors.company_reg) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>
              <div className="form-group col-lg-6">
                <TextField
                  label="Billing Email"
                  name="billing_email"
                  type="email"
                  value={contract.billing_email || ""}
                  error={(errors && errors.billing_email) || false}
                  helperText={(errors && errors.billing_email) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-lg-4">
                <TextField
                  label="Account#"
                  name="account_no"
                  type="text"
                  value={contract.account_no || ""}
                  size="small"
                  fullWidth
                  disabled
                ></TextField>
              </div>
              <div className="form-group col-lg-4">
                <TextField
                  label="Email"
                  name="customer_email"
                  type="email"
                  value={contract.customer_email || ""}
                  error={(errors && errors.customer_email) || false}
                  helperText={(errors && errors.customer_email) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>
              <div className="form-group col-lg-4">
                <TextField
                  label="Tel"
                  name="customer_tel"
                  type="tel"
                  value={contract.customer_tel || ""}
                  error={(errors && errors.customer_tel) || false}
                  helperText={(errors && errors.customer_tel) || ""}
                  onChange={(e) =>
                    uneditable ||
                    handleChange({ name: e.target.name, value: e.target.value })
                  }
                  size="small"
                  fullWidth
                  InputProps={{ readOnly: uneditable }}
                ></TextField>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-lg-6">
                <DatePickerComponent
                  value={contract.start_date}
                  name="start_date"
                  onChange={handleChange}
                  label="Start Date"
                  readOnly={uneditable}
                  error={errors?.start_date}
                />
              </div>
              <div className="form-group col-lg-6">
                <DatePickerComponent
                  value={contract.end_date}
                  name="end_date"
                  onChange={handleChange}
                  label="End Date"
                  readOnly={uneditable}
                  error={errors?.end_date}
                />
              </div>
            </div>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: "#2100b7" }}
          onClick={() => handleSubmit()}
          disabled={type === "view"}
        >
          SAVE
        </Button>
        <Button
          variant="outlined"
          size="large"
          style={{ border: "1px solid #2100b7", color: "#2100b7" }}
          onClick={() => handleClose()}
          className="tour-contract-close-form"
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}
