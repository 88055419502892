import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Collapse } from "@mui/material";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography, Grid } from "@mui/material";
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "../common/ModuleFunctions";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


function Row(props) {
    const row = props.data.row;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell width="5%">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" align="left" width="10%">{row.product_ref}</TableCell>
                <TableCell align="left" width="40%">{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell width="5%">
                    {!props.data.uneditable &&
                        <Tooltip title="Delete service line">
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => props.handleRemove(props.data.key)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="contract lines">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>CC</TableCell>
                                        <TableCell>PC</TableCell>
                                        <TableCell>Min. Term</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Unit Price</TableCell>
                                        <TableCell>Qty</TableCell>
                                        <TableCell>Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.lines.map((lineRow) => (
                                        <TableRow>
                                            <TableCell component="th" scope="row" width="20%">
                                                {ModuleFunctions.getCodeName({ id: lineRow.charge_code_id, codesArray: props.charges })}
                                            </TableCell>
                                            <TableCell align="left" width="10%">
                                                {ModuleFunctions.getCodeName({ id: lineRow.periodic_code_id, codesArray: props.periods })}
                                            </TableCell>
                                            <TableCell align="left" width="5%">{lineRow.minimum_term}</TableCell>
                                            <TableCell align="left" width="44%">{lineRow.description}</TableCell>{/* TODO: This will need to be description for labour ones! */}
                                            <TableCell align="left" width="8%">{lineRow.unit_price}</TableCell>{/* TODO: This will need to be rate for labour ones! */}
                                            <TableCell align="left" width="5%">{lineRow.quantity}</TableCell>
                                            <TableCell align="right" width="8%">{lineRow.price}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        product_ref: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        notes: PropTypes.string,
        lines: PropTypes.arrayOf(
            PropTypes.shape({
                manual: PropTypes.bool,
                dynamic: PropTypes.bool,
                material_id: PropTypes.number,
                component_id: PropTypes.number,
                labour_id: PropTypes.number,
                charge_code_id: PropTypes.number.isRequired,
                periodic_code_id: PropTypes.number.isRequired,
                payment_method_id: PropTypes.number.isRequired,
                minimum_term: PropTypes.number,
                material_ref: PropTypes.string,
                material_name: PropTypes.string,
                description: PropTypes.string,
                material_cost: PropTypes.number,
                rate: PropTypes.number,
                quantity: PropTypes.number,
                vat_rate: PropTypes.number,
                price: PropTypes.number.isRequired,
          }),
        ).isRequired,
    }).isRequired,
};


export default function ServiceLines(props) {
    const [selected, setSelected] = useState(false);

    const handleAddServices = () => {
        const newData = props.parent.services;

        newData.push(selected);

        props.parentChange({
            services: newData
        });
        setSelected(false);

        ModuleFunctions.handleAddService({ data: selected, triggerChange: props.triggerChange });
    };

    const handleRemoveService = (key) => {
        const newData = props.parent.services;

        if(newData[key].id) {
            ModuleFunctions.handleRemoveService({ id: newData[key].id, triggerChange: props.triggerChange });
        }

        newData.splice(key, 1);

        props.parentChange({
            lines: newData
        });
    };

    //-----------------------------------------
    // TODO: ??????????????????????????????????
    const selectDeal = (event, element) => {
        console.log("Selected Deal",element);

        const newService = {
            contract_id: props.parent.contract_id ? props.parent.contract_id : props.contract.id,
            variation_id: props.parent.contract_id ? props.contract.id : null,
            product_id: element.props.dealObj.id,
            product_code: element.props.dealObj.ref,
            name: element.props.dealObj.name,
            notes: element.props.dealObj.notes,
            lines: element.props.dealObj.lines
        };

        setSelected(newService);
    };
    //-----------------------------------------

    useEffect(() => {}, []);


    return (
        <Box sx={{ border: 1, borderColor: "lightgray", borderRadius: 1 }}>
            <Typography sx={{ mt: 1, ml: 2 }} variant="subtitle1" component="div">
                Products
            </Typography>
            <Grid container spacing={2} direction="row" justifyContent="right" alignItems="center">
                <Grid item xs={5}>
                    <FormControl
                        error={props.errors && props.errors.deal_id || false}
                        fullWidth
                    >
                        <InputLabel>Select from the list to add new products from a deal</InputLabel>
                        <Select
                            label="Select from the list to add new products from a deal"
                            name="id"
                            value={selected && selected.deal_id || ""}
                            onChange={(evt, element) => props.uneditable || selectDeal(evt, element)}
                            // inputProps={{ readOnly: props.uneditable }} // TODO: Disabled for now!!!
                            inputProps={{ readOnly: true }}
                            size="small"
                        >
                            {props.deals && props.deals.map((item, key) => {
                                return <MenuItem key={key} value={item.id} dealObj={item}>{item.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    {!props.uneditable && selected &&
                        <ModuleButton
                            btnIcon="add"
                            style={{
                                marginTop: 10,
                                marginRight: 10,
                                background: "red",
                                color: "white"
                            }}
                            tip="Add Product Lines"
                            size="small"
                            onClick={() => handleAddServices()}
                        />
                    }
                </Grid>
            </Grid>

            <TableContainer>
                <Table aria-label="output lines table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="left">Product Code</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.parent.services && props.parent.services.map((row, key) => (
                            <Row
                                data={{ row: row, key: key, uneditable: props.uneditable }}
                                charges={props.charges}
                                periods={props.periods}
                                methods={props.methods}
                                handleRemove={handleRemoveService}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
