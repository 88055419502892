import React, { useEffect } from "react";
import { TitleControl } from "@unity/components";
import ContractSection from "../modules/ContractSection";
import ProductSection from "../modules/ProductSection";

export default function ContractEdit(props) {
  const tc = new TitleControl();

  useEffect(() => {
    const setTitle = () => {
      tc.setControlTitle(<></>, "CONTRACT");
    };
    setTitle();
  }, []);

  return (
    <div style={{ paddingTop: 1 }} className="tour-contract-overview">
      <ContractSection type="edit" />
      <ProductSection type="edit" />
    </div>
  );
}
