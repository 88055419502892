import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";

import ContractForm from "./ContractForm";
import { DateHandler, ContractsApi, BusinessApi } from "@unity/components";
import { getSiteAddress } from "../utils/getSiteAddress";
import { getContactName } from "../utils/getContactName";
import { draftContract } from "./MockData/dealData";
import { useTour } from "../services/TourContext";

const InfoItem = ({ label, value, col }) => {
  return (
    <div className={`form-group col-lg-${col || 12}`}>
      <Box
        sx={{
          p: 1,
          border: 1,
          borderColor: (theme) => theme.palette.grey[400],
          borderRadius: 1,
          display: "flex",
          gap: 1,
        }}
      >
        <Typography
          sx={{ fontWeight: 400, color: (theme) => theme.palette.grey[600] }}
        >
          {label}
        </Typography>
        <Typography style={{ fontWeight: 500 }}>{value}</Typography>
      </Box>
    </div>
  );
};

export default function ContractSection(props) {
  let { id } = useParams();
  const tourOpen = useTour();

  const readOnly = props.type === "view";

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState(false);
  const [site, setSite] = useState(null);
  const [contact, setContact] = useState(null);

  const handleDialog = () => {
    open ? setOpen(false) : setOpen(true);
    if (tourOpen) {
      window.dispatchEvent(
        new CustomEvent("tourStepIndex", { detail: { stepIndex: 1 } })
      );
    }
  };

  const getData = async () => {
    try {
      let res = await ContractsApi.getContract(id);
      const localContract = JSON.parse(JSON.stringify(res.data));
      localContract.start_date = new DateHandler(
        localContract.start_date
      ).UTCToLocalDateString();
      localContract.end_date = new DateHandler(
        localContract.end_date
      ).UTCToLocalDateString();
      setContract(localContract);
      const siteRes = await BusinessApi.siteShow(localContract?.site_id);
      if (siteRes && siteRes.success && siteRes.data) {
        setSite(getSiteAddress(siteRes.data));
      }
      const contactRes = await BusinessApi.contactShow(
        localContract?.contact_id
      );
      if (contactRes && contactRes.success)
        setContact(getContactName(contactRes.data));
    } catch (err) {
      console.log(err);
    }
  };

  const initData = async () => {
    setLoading(true);
    if (tourOpen) {
      setContract(draftContract);
    } else {
      await getData();
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  if (loading) {
    return <span>LOADING...</span>;
  }

  return (
    <div className="block">
      <div className="container-fluid">
        <div className="form-row" style={{ justifyContent: "space-between" }}>
          <div className="form-group">
            <h3>Contract Overview</h3>
          </div>
          {!readOnly ? (
            <div className="form-group">
              <Button
                variant="contained"
                size="large"
                style={{ backgroundColor: "#2100b7" }}
                onClick={() => handleDialog()}
                className="tour-contract-edit"
              >
                EDIT CONTRACT
              </Button>
            </div>
          ) : null}
        </div>
        <div className="form-row">
          <InfoItem label="Name" value={contract.name} />
        </div>
        <div className="form-row">
          <InfoItem label="Notes" value={contract.notes} />
        </div>
        <div className="form-row">
          <InfoItem label="Business" value={contract.customer_name} col={4} />
          <InfoItem label="Contact" value={contact} col={4} />
          <InfoItem label="Site" value={site} col={4} />
        </div>
        <div className="form-row">
          <InfoItem label="Company Reg" value={contract.company_reg} col={6} />
          <InfoItem
            label="Billing Email"
            value={contract.billing_email}
            col={6}
          />
        </div>
        <div className="form-row">
          <InfoItem label="Account #" value={contract.account_no} col={4} />
          <InfoItem
            label="Customer Email"
            value={contract.customer_email}
            col={4}
          />
          <InfoItem
            label="Customer Tel"
            value={contract.customer_tel}
            col={4}
          />
        </div>
        <div className="form-row">
          <InfoItem label="Ref #" value={contract.ref} col={4} />
          <InfoItem label="Start Date" value={contract.start_date} col={4} />
          <InfoItem label="End Date" value={contract.end_date} col={4} />
        </div>
      </div>
      <ContractForm
        label="Edit Contract"
        type="edit"
        contract={contract}
        open={open}
        setOpen={setOpen}
        handleDialog={handleDialog}
      />
    </div>
  );
}
