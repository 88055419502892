import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DocumentVaultAPI, ContractsApi } from "@unity/components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StarIcon from "@mui/icons-material/Star";

export default function ResourceModal(props) {
  const {
    data,
    handleChange,
    lineIndex,
    open,
    setOpen,
    productIndex,
    product_id,
    product_line_id,
    product_group_id,
    product_cat_id,
    sbu_id,
  } = props;

  const [loading, setLoading] = useState({ status: true });
  const [imageLinksArr, setImageLinksArr] = useState(false);
  const [liveProductDocument, setLiveProductDocument] = useState(false);
  const [liveProductSchedules, setLiveProductSchedules] = useState(false);

  const fileIcon = (fileType) => {
    const shortFileType = fileType.slice(-12); //This keeps the last 12 characters of the fiel to ensure a document named the as the file type will still show the correct icon

    if (shortFileType.includes("pdf")) {
      return (
        <div style={{ height: "45px", width: "45px", backgroundColor: "red" }}>
          {" "}
          <span style={{ color: "white" }}>
            <strong>PDF</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("sheet")) {
      return (
        <div
          style={{ height: "45px", width: "45px", backgroundColor: "#118144" }}
        >
          {" "}
          <span style={{ color: "white" }}>
            <strong>Excel</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("presentation")) {
      return (
        <div
          style={{ height: "45px", width: "45px", backgroundColor: "orange" }}
        >
          {" "}
          <span style={{ color: "white" }}>
            <strong>PowerP</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("document")) {
      return (
        <div
          style={{ height: "45px", width: "45px", backgroundColor: "#1a60c2" }}
        >
          {" "}
          <span style={{ color: "white" }}>
            <strong>Word</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("jpeg")) {
      return (
        <div style={{ height: "45px", width: "45px", backgroundColor: "gray" }}>
          {" "}
          <span style={{ color: "white" }}>
            <strong>JPEG</strong>
          </span>
        </div>
      );
    } else if (shortFileType.includes("png")) {
      return (
        <div style={{ height: "45px", width: "45px", backgroundColor: "gray" }}>
          {" "}
          <span style={{ color: "white" }}>
            <strong>PNG</strong>
          </span>
        </div>
      );
    } else {
      return fileType;
    }
  };

  const handleNewDocumentTab = async (vault_id) => {
    const res = await DocumentVaultAPI.generateTempLink(vault_id);
    window.open(res.data, "_blank");
  };

  const generateImageLink = async () => {
    const linkArrayCopy = [];
    for (let i = 0; i < liveProductDocument.length; i++) {
      const shortFileType = liveProductDocument[i].type.slice(-12);

      if (shortFileType.includes("jpeg") || shortFileType.includes("png")) {
        const res = await DocumentVaultAPI.generateTempLink(
          liveProductDocument[i].vault_id
        );
        linkArrayCopy.push({
          id: i,
          link: res.data,
          vault_id: liveProductDocument[i].vault_id,
        });
      } else {
        linkArrayCopy.push({
          id: i,
          link: null,
          vault_id: liveProductDocument[i].vault_id,
        });
      }
    }
    setImageLinksArr(linkArrayCopy);
  };

  const createImagePreview = (index) => {
    if (imageLinksArr[index].link != null) {
      return (
        <img
          style={{ height: "50px", width: "100px", objectFit: "contain" }}
          src={imageLinksArr[index].link}
        ></img>
      );
    } else {
      return <span>Preview not available</span>;
    }
  };

  const getProductResources = async () => {
    const obj = { product_id };
    const res = await ContractsApi.getDocuments(obj);
    if (res.success) {
      setLiveProductDocument(res.data);
    }

    const sheduleRes = await ContractsApi.getSchedules({
      product_id,
      prod_line_id: product_line_id,
      prod_group_id: product_group_id,
      prod_cat_id: product_cat_id,
      sbu_id,
    });
    if (res.success) {
      setLiveProductSchedules(sheduleRes.data);
    }
  };

  const loadInitData = async () => {
    setLoading({ status: true });
    await getProductResources();
    setLoading({ status: false });
  };

  useEffect(() => {
    if (open) loadInitData();
  }, [open]);

  if (!open) {
    return null;
  }

  const handleClose = () => {
    setLiveProductDocument(false);
    setOpen(false);
  };

  return (
    <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle>Resources</DialogTitle>
      <DialogContent>
        {loading.status ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <br />
            <span>{loading.data}</span>
          </div>
        ) : (
          <>
            <h2>Resources</h2>
            <div className="form-row">
              <div className="form-group col-lg-12">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Preview</TableCell>
                        <TableCell>name</TableCell>
                        <TableCell>File Type</TableCell>
                        <TableCell>File Size</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {liveProductDocument &&
                        liveProductDocument.map((row, key) => (
                          <TableRow
                            //key={row.description}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {createImagePreview(key) || ""}
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {row.name || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {fileIcon(row.type) || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.size || ""}
                            </TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                              style={{ width: "200px" }}
                            >
                              <IconButton>
                                <VisibilityIcon
                                  style={{ color: "#2100b7" }}
                                  onClick={() =>
                                    handleNewDocumentTab(row.vault_id)
                                  }
                                />
                              </IconButton>

                              {row.primary ? (
                                <IconButton>
                                  <StarIcon
                                    style={{ color: "#2100b7" }}
                                    onClick={() =>
                                      handleNewDocumentTab(row.vault_id)
                                    }
                                  />
                                </IconButton>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>

            <h2>Schedules</h2>
            <div className="form-row">
              <div className="form-group col-lg-12">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>name</TableCell>
                        <TableCell>File Type</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {liveProductSchedules &&
                        liveProductSchedules.map((row, key) => (
                          <TableRow
                            //key={row.description}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.document_title || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {fileIcon("pdf") || ""}
                            </TableCell>

                            <TableCell component="th" scope="row">
                              <IconButton>
                                <VisibilityIcon
                                  style={{ color: "#2100b7" }}
                                  onClick={() => handleNewDocumentTab(row.id)}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: "#2100b7" }}
          onClick={() => handleClose()}
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
