import React, { useState, useEffect } from "react";
import { TitleControl } from "@unity/components";
import {
  CanCreate,
  CanRead,
  CanReadAll,
  CanUpdate,
  CanModuleAdmin,
} from "../services/Permissions";
import ModuleTabs from "../common/ModuleTabs";
import ModuleFunctions from "../common/ModuleFunctions";
import PendingContracts from "../modules/PendingContracts";
import ActiveContracts from "../modules/ActiveContracts";
import ExpiredContracts from "../modules/ExpiredContracts";
import Settings from "../modules/Settings";
import { useHistory } from "react-router";
import DraftContracts from "../modules/DraftContracts";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ContractForm from "../modules/ContractForm";
import { useTour } from "../services/TourContext";

export default function Contracts({ route, context }) {
  const tourOpen = useTour();

  const [tabValue, setTabValue] = useState(0);
  const [bus, setBus] = useState(false);

  const [open, setOpen] = useState(false);

  const create = CanCreate("contracts");
  const read = CanRead("contracts");
  const edit = CanUpdate("contracts");
  const admin = CanModuleAdmin("contracts");
  const readAll = CanReadAll("contracts");

  const tc = new TitleControl();

  const setTitle = () => {
    tc.setControlTitle(
      <>
        <Button
          //color="error"
          variant="contained"
          startIcon={<AddIcon />}
          //stylename="primary"
          style={{
            background: context.theme.top_menu.button_background,
            color: context.theme.top_menu.button_font,
          }}
          //onClick={() => history.push('/contracts/create')}
          onClick={() => handleDialog()}
          className="tour-new-contract"
        >
          Create Contract
        </Button>
      </>,
      "CONTRACT"
    );
  };

  const handleDialog = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const busChange = (data) => {
    setBus(data);
  };

  const handleChange = async (event, newValue) => {
    setTabValue(newValue);
    if (tourOpen) {
      window.dispatchEvent(
        new CustomEvent("tourStepIndex", { detail: { stepIndex: 1 } })
      );
    }
  };

  useEffect(() => {
    setTitle();
    ModuleFunctions.getBusinesses({ change: busChange });
  }, []);

  const tabs = [
    { icon: "", label: "Draft Contracts" },
    {
      icon: "",
      label: "Pending Contracts",
      className: "tour-contract-pending-tab",
    },
    {
      icon: "",
      label: "Active Contracts",
      className: "tour-contract-active-tab",
    },
    {
      icon: "",
      label: "Expired Contracts",
      className: "tour-contract-expired-tab",
    },
  ];

  if (admin) {
    tabs.push({ icon: "", label: "module settings" });
  }

  return (
    <>
      <ModuleTabs
        tabs={tabs}
        value={tabValue}
        setValue={setTabValue}
        handleChange={handleChange}
        className="tour-contract-tabs"
      />
      {tabValue === 0 && (
        <div style={{ paddingTop: 86 }}>
          <DraftContracts
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            readAll={readAll}
            bus={bus}
          />
        </div>
      )}
      {tabValue === 1 && (
        <div style={{ paddingTop: 86 }}>
          <PendingContracts
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            readAll={readAll}
            bus={bus}
          />
        </div>
      )}
      {tabValue === 2 && (
        <div style={{ paddingTop: 86 }}>
          <ActiveContracts
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            readAll={readAll}
            bus={bus}
          />
        </div>
      )}
      {tabValue === 3 && (
        <div style={{ paddingTop: 86 }}>
          <ExpiredContracts
            context={context}
            create={create}
            read={read}
            edit={edit}
            admin={admin}
            readAll={readAll}
            bus={bus}
          />
        </div>
      )}
      {tabValue === 4 && (
        <div style={{ paddingTop: 86 }}>
          <Settings context={context} />
        </div>
      )}
      <ContractForm
        label="Create new Contract"
        type="create"
        context={context}
        route={route}
        showBtns={true}
        open={open}
        setOpen={setOpen}
        handleDialog={handleDialog}
      />
    </>
  );
}
