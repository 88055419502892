import React, { useEffect, useState } from "react";
import { ContractsApi, ApiLoaderComponent, LoadingComponent, AppHeaderComponent } from "@unity/components";
import { useHistory } from "react-router";
import { CanUpdate } from "../../services/Permissions";
import OvrVariationForm from "../../modules/OvrVariationForm";
import LineVariationForm from "../../modules/LineVariationForm";
import ModuleFunctions from "../../common/ModuleFunctions";

// ***************************************************************************************************************************
// TODO:
// HONESTLY, WITH A FEW VARIABLES, ALL OF THESE CAN USE THE CONTRACT FORM ASWELL AND WE CAN GET RID OF THE OVR & LINE FORMS!!!
// ***************************************************************************************************************************

export default function VariationEdit(props) {
  const [variation, setVariation] = useState(false);
  const [bus, setBus] = useState(false);
  const [charges, setCharges] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [methods, setMethods] = useState(false);
  const [clients, setClients] = useState(false);
  const [reasons, setReasons] = useState(false);
  const [contacts, setContacts] = useState(false);
  const [sites, setSites] = useState(false);
  const [deals, setDeals] = useState(false);
  const [records, setRecords] = useState(false);
  const [errors, setErrors] = useState(props.route.location.state.hasErrors || false);
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!

  let history = useHistory();

  const edit = CanUpdate("contracts");

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  const chargesChange = (data) => {
      setCharges(data);
  };

  const periodsChange = (data) => {
      setPeriods(data);
  };

  const methodsChange = (data) => {
      setMethods(data);
  };

  const busChange = (data) => {
    setBus(data);
  };
  const clientsChange = (data) => {
    setClients(data);
  };

  const contactsChange = (data) => {
    setContacts(data);
  };

  const sitesChange = (data) => {
    setSites(data);
  };

  const dealsChange = (data) => {
    setDeals(data);
  };

  const recordsChange = (data) => {
    setRecords(data);
  };

  const reasonsChange = (data) => {
    setReasons(data);
  };

  const variationChange = (data) => {
    setVariation(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const handleSubmit = async () => {
    setLoading({ status: true, data: "Updating your Contract variation, Please Wait...." });

    const res = await ContractsApi.updateVariation(props.route.match.params.id, variation);

    if(res.success) {
      setLoading({ status: true, data: "Successfully updated Contract variation" });
      setTimeout(() => {
        history.push("/contracts/index");
        setLoading({ status: false });
      }, 3000);
    } else {
        if(res.errors) {
            setErrors(res.errors);
            setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
            setTimeout(() => {
                setLoading({ status: false });
            }, 3000);
        } else {
            setLoading({ status: true, data: res.message });
            setTimeout(() => {
                history.push("/contracts/index");
                setLoading({ status: false });
            }, 3000);
        }
    }
  };

  useEffect(() => {
    ModuleFunctions.getVariation({ id: props.route.match.params.id, change: variationChange });
    ModuleFunctions.getBusinesses({ change: busChange });
    ModuleFunctions.getClients({ change: clientsChange });
    ModuleFunctions.getDeals({ change: dealsChange });
    ModuleFunctions.getChargeCodes({ change: chargesChange });
    ModuleFunctions.getPeriodicCodes({ change: periodsChange });
    ModuleFunctions.getPaymentMethods({ change: methodsChange });
    ModuleFunctions.getEnquiryTypes({ change: reasonsChange });
  }, [fetchTrigger]);

  if (edit && variation) {
      return (
        <>
          <AppHeaderComponent
            context={props.context}
            theme={props.context.theme}
            saveBtn={variation.changed ? handleSubmit : null}
            name="contracts"
            subpage="variation"
            subpage2="edit"
          />

          <div>
            {variation.overall_variation ? (
              <OvrVariationForm
                type="edit"
                edit={edit}
                bus={bus}
                reasons={reasons}
                clients={clients}
                contacts={contacts}
                sites={sites}
                variation={variation}
                deals={deals}
                records={records}
                charges={charges}
                periods={periods}
                methods={methods}
                errors={errors}
                variationChange={variationChange}
                contactsChange={contactsChange}
                sitesChange={sitesChange}
                triggerChange={triggerChange}
              />
            ) : (
              <LineVariationForm
                type="edit"
                edit={edit}
                bus={bus}
                reasons={reasons}
                clients={clients}
                contacts={contacts}
                sites={sites}
                variation={variation}
                deals={deals}
                records={records}
                charges={charges}
                periods={periods}
                methods={methods}
                errors={errors}
                variationChange={variationChange}
                contactsChange={contactsChange}
                sitesChange={sitesChange}
                triggerChange={triggerChange}
              />
            )}
          </div>
          
          <ApiLoaderComponent
            status={loading.status}
            data={loading.data}
          />
        </>
      );
  } else {
    return <LoadingComponent />;
  }
}
