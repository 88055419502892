import React from "react";
import { AppHeaderComponent } from "@unity/components";
import ContractSection from "../modules/ContractSection";
import ProductSection from "../modules/ProductSection";

export default function ContractView(props) {
  return (
    <>
      <AppHeaderComponent
        context={props.context}
        theme={props.context.theme}
        name="contracts"
        subpage="view"
      />
      <div style={{ paddingTop: 0.5 }}>
        <ContractSection type="view" />
        <ProductSection type="view" />
      </div>
    </>
  );
}
