import React from "react";
import ModuleButton from "../common/ModuleButton";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function VariationTypeForm(props) {

  const handleSelect = (type, button) => {
    const newData = {};
    newData['overall_variation'] = type;

    props.dataChange(newData);
    props.setSelected(button);
  };

  return (
    <div className="block">
      <div className="container-fluid">

        <Box>
          <Typography sx={{ m: 1 }} align="center" variant="h6" component="div">
            Select the type of Variation would you like to create?
          </Typography>
          <Grid  sx={{ m: 1 }} container spacing={2} direction="row" alignItems="center" justifyContent="center">
            <Grid item>
              <ModuleButton
                text="overall variation"
                style={{
                  background: (props.selected && props.selected === "overall") ? props.context.theme.sidebar.background : 'gray',
                  color: "white",
                }}
                onClick={() => handleSelect(true, "overall")}
              />
            </Grid>
            <Grid item>
              <ModuleButton
                text="line variation"
                style={{
                  background: (props.selected && props.selected === "line") ? props.context.theme.sidebar.background : 'gray',
                  color: "white",
                }}
                onClick={() => handleSelect(false, "line")}
              /> 
            </Grid>
          </Grid>
        </Box>

      </div>
    </div>
  );
}
